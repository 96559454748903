<template>
    <v-container fluid fill-height>
        <v-row align-center justify-center>
            <v-col>
                <v-card class="elevation-6" style="max-width: 400px;margin: 0% auto">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>退出</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            成功退出
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" to='/auth/local'>去登录</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        mounted: function () {
            window.localStorage.removeItem('me');
        },
    }
</script>